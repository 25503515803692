@import url('https://fonts.googleapis.com/css?family=Roboto:400,700,700i,900,900i&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Lato:wght@100;400;700;900&display=swap');

body {
  background: url(./img/TeamWorking_Reception_Signage.png);
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  position: relative;
  min-height: 100vh;
  overflow: hidden;
  font-family: 'Roboto', sans-serif;


}

.Display {
  position: absolute;
  width: 100%;
  min-height: 100%;
}


  .NavbarBrand {
      height: auto;
      font-size: 90em !important;
}

.transparentBypass {
  opacity: 1 !important;
}