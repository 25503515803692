.NavbarBrand {
    font-size:45em !important;
    
}



@import url('https://fonts.googleapis.com/css?family=Comfortaa&display=swap');



.arrow-right {
    background-color: #f8f9fa;
    /* box-shadow: 0 0 3px .5px rgba(0,0,0,0.8); */
    height: 400px;
    right: -230px;
    position: fixed;
    bottom: -130px;
    width: 600px;
    overflow: hidden;
    
    -webkit-transform: rotate(-45deg);
  }

  .arrow-right img {
    color: #f5f5f5;
    font-family: sans-serif;
    font-size: 1.005em;
    right: 190px;
    bottom: 170px;
    position: fixed;
    width: 220px;
    -webkit-transform: rotate(45deg);
    overflow: hidden;

  }