.events {
    margin-top: 0px;
    background-color: #1f1f1f;
    color: #fff;
}

strong {
    text-align: center;
}

.card {
    padding: 40px;
    background-color: #f1f1f1b8;
    border-radius: 5px;
    display: block;
    width: 95%;
    margin: auto;
    margin-top: 15px;
    overflow: hidden;
}

.event-title {
    font-size: 4em;
    margin: 0px;
    font-weight: bold;
    font-family: Lato;
    font-weight: 900;
}

.event-location {
    font-size: 2.6em;
    margin: 0px;
    margin-top: 25px;
    font-weight: bold;
    display: inline-block;
    font-family: Lato;
    font-weight: 900;
}

.event-time {
    float: right;
    font-size: 2.4em;
    display: inline-block;
    margin: 0px;
    margin-top: 27px;
    font-family: Lato;
    font-weight: 700;
}

.cardsUnder {
    height: 100%;
    width: 100%;
}